import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AppState } from '../../reducers';
import { isLoggedIn, getUserRole } from '../_selectors/auth.selectors';

@Injectable()
export class RoleGuard  {

  constructor(private store: Store<AppState>, private router: Router, ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const availableRoles: string[] = Array.isArray(routeSnapshot.data['roles']) ? routeSnapshot.data['roles'] : [];
    return combineLatest([
      this.store.pipe(select(isLoggedIn)),
      this.store.pipe(select(getUserRole)),
    ]).pipe(
      tap(([loggedIn]) => {
        if (!loggedIn) {
          this.router.navigateByUrl('/auth/login');
        }
      }),
      map(([loggedIn, userRole]) => {
        return Boolean(loggedIn && userRole) && availableRoles.includes(userRole as string);
      }),
      tap((allow) => {
        if (!allow) {
          this.router.navigateByUrl('welcome');
        }
      }),
    );
  }
}
